<mat-card *ngIf="!withoutOptions || withSettings" class="table-header">
    <div class="mt-2"
         [class.with-settings]="(withSettings && !filter) || (!withSettings && filter)"
         [class.with-settings-and-filter]="withSettings && filter"
    >
        <ng-content select="[customOptions]"></ng-content>
        <div *ngIf="filter" class="settings-btn">
            <button
                color="primary"
                mat-mini-fab
                matTooltipPosition="above"
                [matTooltip]="'common.drop_filters' | translate"
                (click)="onFilterClear()"
            >
                <mat-icon>filter_alt_off</mat-icon>
            </button>
        </div>
        <div *ngIf="withSettings" class="settings-btn">
            <button
                color="primary"
                mat-mini-fab
                matTooltipPosition="above"
                [matTooltip]="'common.column_settings' | translate"
                [matMenuTriggerFor]="menu"
            >
                <mat-icon>settings</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <div class="p-4">
                    <!--                    TODO: uncomment when will be ready to add SELECT ALL checkbox for settings-->
                    <!--                    <mat-list class="mb-2" *ngIf="isSelectAllSettingsVisible">-->
                    <!--                        <mat-checkbox [formControl]="allSettingsSelected" (click)="$event.stopPropagation()">-->
                    <!--                            {{ 'common.select_all' | translate }}-->
                    <!--                        </mat-checkbox>-->
                    <!--                    </mat-list>-->
                    <mat-list *ngFor="let column of tableColumns; let i = index">
                        <mat-checkbox
                            [checked]="displayedColumns.includes(column.id)"
                            (click)="$event.stopPropagation()"
                            (change)="onSettingsChange(i)">
                            {{ column.header }}
                        </mat-checkbox>
                    </mat-list>
                </div>
            </mat-menu>
        </div>
    </div>
</mat-card>

<mat-card class="mt-2">
    <div class="table-content">
        <table #table
               *ngIf="!isSpinnerVisible; else spinner;"
               class="nekta-table"
               mat-table
               matSort
               matSortDisableClear
               [dataSource]="dataSource"
               [matSortActive]="sort?.active"
               [matSortDirection]="sort?.direction"
               [multiTemplateDataRows]="!!expandedRef"
               [trackBy]="trackByFn"
               (matSortChange)="onSort($event)"
        >
            <ng-container *ngIf="withCheckboxes" [matColumnDef]="'checkbox'" sticky>
                <th *matHeaderCellDef mat-header-cell class="actions-th">
                    <mat-checkbox [formControl]="allTableRowsSelected"></mat-checkbox>
                </th>
                <td *matCellDef="let item;" mat-cell
                    (click)="$event.stopPropagation()"
                >
                    <mat-checkbox
                        [checked]="isItemChecked(item)"
                        (change)="changeSelectItem($event, item)"
                    ></mat-checkbox>
                </td>
            </ng-container>
            <ng-container
                *ngFor="let column of tableColumns"
                [matColumnDef]="column.id"
            >
                <th
                    *matHeaderCellDef
                    mat-header-cell
                    [mat-sort-header]="column?.sort ? column.id : null"
                    [disabled]="!column?.sort"
                    disableClear="true"
                >
                    <ng-container *ngIf="!column?.thRef && !column?.filterOptions;">
                        {{ column.header }}
                    </ng-container>
                    <ng-container *ngIf="column?.filterOptions">
                        {{ column.header }}
                        <nekta-table-filter
                            [selected]="filter?.[column.id]"
                            [options]="column.filterOptions"
                            (filterChange)="onFilterChange(column.id, $event)"
                        ></nekta-table-filter>
                    </ng-container>
                    <ng-container *ngIf="column?.thRef" [ngTemplateOutlet]="column?.thRef"></ng-container>
                </th>
                <td
                    *matCellDef="let item; let i = index"
                    mat-cell
                    (click)="column.withAction ? $event.stopPropagation() : undefined"
                >
                    <ng-container *ngIf="!column?.tdRef">
                        {{ item?.[column.id] ?? '-' }}
                    </ng-container>
                    <ng-container *ngIf="column?.tdRef"
                                  [ngTemplateOutlet]="column?.tdRef"
                                  [ngTemplateOutletContext]="{item:item}"
                    ></ng-container>
                </td>
            </ng-container>
            <ng-container
                *ngIf="actionsRef"
                matColumnDef="actions"
                stickyEnd
            >
                <th *matHeaderCellDef mat-header-cell class="actions-th"></th>
                <td
                    *matCellDef="let item; let i = index"
                    mat-cell
                    (click)="$event.stopPropagation()"
                >
                    <ng-container [ngTemplateOutlet]="actionsRef"
                                  [ngTemplateOutletContext]="{item:item}"></ng-container>
                </td>
            </ng-container>


            <ng-container *ngIf="expandedRef" matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let item" [attr.colspan]="displayedColumns?.length">
                    <ng-container *ngIf="item === expandedElement">
                        <ng-container *ngTemplateOutlet="expandedRef"></ng-container>
                    </ng-container>
                </td>
            </ng-container>

            <ng-container *ngIf="footerRef" matColumnDef="footerFull">
                <td
                    mat-footer-cell
                    *matFooterCellDef
                    [attr.colspan]="actionsRef ? displayedColumns.length + 1 : displayedColumns.length"
                >
                    <ng-container *ngTemplateOutlet="footerRef"></ng-container>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <ng-container *ngIf="expandedRef; else defaultTr;">
                <tr mat-row *matRowDef="let item; columns: displayedColumns;"
                    class="element-row"
                    [class.expanded-row]="expandedElement === item"
                    (click)="onExpandElement(item)">
                </tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
            </ng-container>

            <ng-container *ngIf="footerRef">
                <tr mat-footer-row *matFooterRowDef="['footerFull']" class="element-row"></tr>
            </ng-container>

            <ng-template #defaultTr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="onRowClick(row)"></tr>
            </ng-template>

            <tr class="mat-row" *matNoDataRow>
                <td [colSpan]="actionsRef ? displayedColumns.length + 1 : displayedColumns.length">
                    <div class="flex-center m-2 p-2">
                        <mat-icon color="warn" class="mr-2">warning</mat-icon>
                        <span class="safe-text-word">
                            {{ customErrorMessage ? customErrorMessage : ('common.no_data_to_display' | translate) }}
                        </span>
                    </div>
                    <div class="flex-center pv-2">
                        <button
                            mat-button
                            (click)="onFilterClear()"
                        >
                            <mat-icon>clear</mat-icon>
                            {{ 'reports.infrastructure.reset_filter' | translate }}
                        </button>
                    </div>
                </td>
            </tr>
        </table>
    </div>
</mat-card>

<mat-card class="paginator" *ngIf="pagination">
    <mat-paginator
        #paginator
        showFirstLastButtons
        [length]="pagination?.length"
        [pageIndex]="pagination?.pageIndex"
        [pageSize]="pagination?.pageSize"
        [pageSizeOptions]="pagination?.pageSizeOptions"
        (page)="onPaginationChange($event)"
    ></mat-paginator>
</mat-card>

<ng-template #spinner>
    <mat-card class="spinner">
        <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
    </mat-card>
</ng-template>
