import { createReducer, on } from '@ngrx/store';
import { normalizeArrayById } from '@core/helpers';
import { DirectoriesActions } from '@store/directories/directories.action';

export interface DirectoriesState {
    deviceBrands: any;
    deviceGroups: any;
    deviceInterfaces: any;
    brands: any[];
    interfaces: any[];
    messageGroups: any;
    messageTypes: any;
}

const initialState: DirectoriesState = {
    deviceBrands: {},
    deviceGroups: {},
    deviceInterfaces: {},
    brands: [],
    interfaces: [],
    messageGroups: [],
    messageTypes: null
};
export const directoriesReducer = createReducer(
    initialState,
    on(DirectoriesActions.loadbrands, (state, { payload }) => {
        return {
            ...state,
            deviceBrands: normalizeArrayById(payload),
            brands: payload
        };
    }),
    on(DirectoriesActions.loaddevicegroups, (state, { payload }) => {
        return {
            ...state,
            deviceGroups: normalizeArrayById(payload)
        };
    }),
    on(DirectoriesActions.loadinterfaces, (state, { payload }) => {
        return {
            ...state,
            deviceInterfaces: normalizeArrayById(payload),
            interfaces: payload
        };
    }),
    on(DirectoriesActions.loadmessagegroups, (state, { payload }) => {
        return {
            ...state,
            messageGroups: payload
        };
    }),
    on(DirectoriesActions.loadmessagetypes, (state, { payload }) => {
        return {
            ...state,
            messageTypes: payload
        };
    }),
    on(DirectoriesActions.reset, (state) => {
        return { ...initialState };
    })
);
