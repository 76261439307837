import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {CloudComponent} from 'src/app/cloud/cloud.component';
import {Observable, ReplaySubject} from 'rxjs';
import {DevicesService} from '../../../../services/devices.service';
import * as moment from 'moment';
import 'moment-timezone';
import * as echarts from 'echarts';
import {CheckAccess, CloudService, DateManipulations} from 'src/app/cloud/cloud.service';
import {MeteringDevicesDataComponent} from '../data.component';
import 'zrender/lib/svg/svg';
import * as _ from 'lodash';
import {ErrorsService} from '@core/services/errors.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {TranslateLanguageService} from '../../../../../../core/services/translate-language.service';
import {takeUntil} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {selectDeviceFieldTitlesState} from '../../../../../../store/selectors/selectors';

/**
 * @deprecated will be deleted
 */

// DELETE not used

@Component({
    selector: 'metering-devices-data-archives-system-1',
    templateUrl: './archives_system_1.component.html',
    styleUrls: ['./archives_system_1.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MeteringDevicesArchivesSystem1Component implements OnInit, OnDestroy {
    app;
    loadApp = this._cloudService.appConst.subscribe((app: any) => {
        this.app = app;
    });
    showChart = true;
    chart;
    subscribers = [];

    @Input() device: any;
    @Input() dateForm: any;
    @Input() msgGroup: any;
    isSpinnerVisible = true;

    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    dataSource = new MatTableDataSource();

    displayedColumns: string[];
    availableColumns: string[];
    fieldsTitles: any[] = [];
    settings;
    lang: string;
    private readonly destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

    constructor(
        private store: Store<{}>,
        private translate: TranslateService,
        public cloud: CloudComponent,
        private devicesService: DevicesService,
        private _cloudService: CloudService,
        private _dataComponent: MeteringDevicesDataComponent,
        private translateLanguageService: TranslateLanguageService,
        public _checkAccess: CheckAccess,
        private errors: ErrorsService,
        public dateManipulations: DateManipulations,
        private cdr: ChangeDetectorRef
    ) {
        CloudService.setLanguage(translate);
    }

    ngOnInit() {
        this._cloudService.settings.subscribe((settings) => (this.settings = settings));
        this.subscribers[this.subscribers.length] = this._dataComponent.refreshData.subscribe((value) => {
            if (this._dataComponent.currentTab == 'archives_system_1') {
                this.isSpinnerVisible = true;
                setTimeout((_) => {
                    this.store
                        .pipe(select(selectDeviceFieldTitlesState)).subscribe((res) => {
                        res.filter((el: any) => el.id === this.device.device_fields_titles.find((elem) => elem.id === el.id)?.id).
                        forEach((field: any) => this.fieldsTitles[field.name] = field); });
                    this.dataSource.sort = this.sort;
                    this.dataSource.paginator = this.paginator;
                    this.subscribers[this.subscribers.length] = this.getMessages().subscribe((_) => {
                        setTimeout(() => {
                            this.isSpinnerVisible = false;
                            this.cdr.detectChanges();
                        }, 500);
                    });
                }, 500);
            }
        });

        this.translateLanguageService.currentLanguage$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((lang) => {
                this.lang = lang;
            });
    }

    getMessages() {
        return new Observable<any>((observer) => {
            if (this.chart !== undefined) {
                this.chart.dispose();
            }
            this.subscribers[this.subscribers.length] = this.devicesService
                .getMessages(
                    this.device.id,
                    101,
                    this.devicesService.dateForm.startDate,
                    this.devicesService.dateForm.stopDate,
                    0,
                    10000,
                    'archives_system_1'
                )
                .subscribe(
                    (messages) => {
                        if (messages.data.length == 0 || messages.fields_headers.length == 0) {
                            this.dataSource.data = [];
                            setTimeout(() => {
                                this.isSpinnerVisible = false;
                                this.cdr.detectChanges();
                            }, 500);
                            return;
                        }
                        this.dataSource.data = messages.data;
                        this.availableColumns = this.sortFields(messages.fields_headers);
                        let filteredColumns = this.availableColumns.filter((column) => {
                            return (
                                column.search(/start_/i) === -1 &&
                                column.search(/end_/i) === -1 &&
                                column.search(/transformation_ratio/i) === -1 &&
                                column.search(/realdatetime/i) === -1
                            );
                        });
                        this.displayedColumns = this.sortFields(
                            _.get(
                                this.settings,
                                'deviceFields.model_id_' + this.device.model.id + '.archives_system_1',
                                _.clone(filteredColumns)
                            ),
                            this.availableColumns
                        );
                        if (
                            this.device.model.tabs !== null &&
                            !_.isUndefined(this.device.model.tabs['archives_system_1']) &&
                            !_.isUndefined(this.device.model.tabs['archives_system_1']['chart_fields']) &&
                            this.device.model.tabs['archives_system_1']['chart_fields'][0] !== null
                        ) {
                            this.prepareMessagesForChart(messages.fields_headers, messages.data).then(
                                (chartData: any) => {
                                    setTimeout((_) => {
                                        if (chartData.inputs.length > 0) {
                                            this.renderChart(chartData, 'deviceChart');
                                        } else {
                                            this.showChart = false;
                                        }
                                    }, 1000);
                                }
                            );
                        } else {
                            this.showChart = false;
                        }
                        setTimeout((_) => {
                            observer.next(null);
                            observer.complete();
                        }, 500);
                    },
                    (error) => {
                        throw error;
                    }
                );
        });
    }

    changeFieldVisible(field) {
        if (this.displayedColumns.indexOf(field) === -1) {
            this.displayedColumns.push(field);
        } else {
            _.pull(this.displayedColumns, field);
        }
        this.displayedColumns = this.sortFields(this.displayedColumns, this.availableColumns);
        _.set(
            this.settings,
            'deviceFields.model_id_' + this.device.model.id + '.archives_system_1',
            this.displayedColumns
        );
        this._cloudService.settings = this.settings;
    }
    checkCheckedField(field) {
        return this.displayedColumns.indexOf(field) === -1 ? false : 'checked';
    }

    sortFields(columns, mainArr = null) {
        if (_.isNull(mainArr)) {
            mainArr = [
                'datetime',
                'in1',
                'start_in1',
                'end_in1',
                'delta_in1',
                'in2',
                'start_in2',
                'end_in2',
                'delta_in2',
                'in3',
                'start_in3',
                'end_in3',
                'delta_in3',
                'in4',
                'start_in4',
                'end_in4',
                'delta_in4',
                'tariff1',
                'start_tariff1',
                'end_tariff1',
                'delta_tariff1',
                'tariff2',
                'start_tariff2',
                'end_tariff2',
                'delta_tariff2',
                'tariff3',
                'start_tariff3',
                'end_tariff3',
                'delta_tariff3',
                'tariff4',
                'start_tariff4',
                'end_tariff4',
                'delta_tariff4',
                'tariff5',
                'start_tariff5',
                'end_tariff5',
                'delta_tariff5'
            ];
        }
        const result = mainArr.filter(function (field) {
            return columns.indexOf(field) !== -1;
        });
        return result.concat(columns.filter((el) => !result.includes(el)));
    }

    getFormatedDate(value) {
        return moment.unix(this.dateManipulations.dateLocal(value)).utc().format('DD.MM.YYYY HH:mm');
    }

    typeof(value) {
        return typeof value;
    }

    async prepareMessagesForChart(inputs, messages): Promise<any> {
        return new Promise<any>((resolve) => {
            try {
                const data = { series: [], labels: [], inputs: [] };
                let chartType = _.get(this.settings, 'chartType.model_id_' + this.device.model.id, 'line');
                for (const input of inputs) {
                    let index;
                    if (
                        _.isArray(_.get(this.device, 'model.tabs.archives_system_1.chart_fields', null)) &&
                        this.device.model.tabs.archives_system_1.chart_fields.length > 0
                    ) {
                        if (
                            data.inputs.indexOf(input) === -1 &&
                            this.device.model.tabs.archives_system_1.chart_fields.indexOf(input) !== -1
                        ) {
                            if (this.fieldsTitles.hasOwnProperty(input)) {
                                data.inputs.push(this.fieldsTitles[input].title);
                                index = data.series.push({
                                    name: this.fieldsTitles[input] ? this.fieldsTitles[input].title : input,
                                    data: [],
                                    type: chartType,
                                    markPoint: {
                                        data: [
                                            { type: 'max', name:  this.translate.instant('devices.data_show.max') },
                                            { type: 'min', name:  this.translate.instant('devices.data_show.min') }
                                        ],
                                        symbolSize: [100, 20],
                                        symbol: 'rect',
                                        symbolRotate: 'arrow'
                                    }
                                });
                            } else {
                                data.inputs.push(input);
                                index = data.series.push({
                                    name: this.fieldsTitles[input] ? this.fieldsTitles[input].title : input,
                                    data: [],
                                    type: chartType,
                                    markPoint: {
                                        data: [
                                            { type: 'max', name: this.translate.instant('devices.data_show.max') },
                                            { type: 'min', name: this.translate.instant('devices.data_show.min') }
                                        ],
                                        symbolSize: [100, 20],
                                        symbol: 'rect',
                                        symbolRotate: 'arrow'
                                    }
                                });
                            }
                        }
                    } else {
                        if (data.inputs.indexOf(input) === -1 && input.search(/delta/i) !== -1) {
                            if (this.fieldsTitles.hasOwnProperty(input)) {
                                data.inputs.push(this.fieldsTitles[input].title);
                                index = data.series.push({
                                    name: this.fieldsTitles[input] ? this.fieldsTitles[input].title : input,
                                    data: [],
                                    type: chartType,
                                    markPoint: {
                                        data: [
                                            { type: 'max', name: this.translate.instant('devices.data_show.max') },
                                            { type: 'min', name: this.translate.instant('devices.data_show.min') }
                                        ],
                                        symbolSize: [100, 20],
                                        symbol: 'rect',
                                        symbolRotate: 'arrow'
                                    }
                                });
                            } else {
                                data.inputs.push(input);
                                index = data.series.push({
                                    name: this.fieldsTitles[input] ? this.fieldsTitles[input].title : input,
                                    data: [],
                                    type: chartType,
                                    markPoint: {
                                        data: [
                                            { type: 'max', name: this.translate.instant('devices.data_show.max') },
                                            { type: 'min', name: this.translate.instant('devices.data_show.min') }
                                        ],
                                        symbolSize: [100, 20],
                                        symbol: 'rect',
                                        symbolRotate: 'arrow'
                                    }
                                });
                            }
                        }
                    }

                    for (const message of messages) {
                        if (
                            _.isArray(_.get(this.device, 'model.tabs.archives_system_1.chart_fields', null)) &&
                            this.device.model.tabs.archives_system_1.chart_fields.length > 0
                        ) {
                            if (this.device.model.tabs.archives_system_1.chart_fields.indexOf(input) !== -1) {
                                data.series[index - 1].data.push(parseFloat(message[input]).toFixed(3));
                            }
                        } else {
                            if (input.search(/delta/i) !== -1) {
                                data.series[index - 1].data.push(parseFloat(message[input]).toFixed(3));
                            }
                        }
                    }
                }
                // }
                this.generateLabels(messages).then(
                    (labels: any) => {
                        data.labels = this.unique(labels);
                        resolve(data);
                    },
                    (error) => {
                        throw error;
                    }
                );
            } catch (e) {
                throw e;
            }
        });
    }

    async generateLabels(messages) {
        return new Promise((resolve) => {
            const labels = [];
            for (const message of messages) {
                labels.push(
                    moment
                        .unix(this.dateManipulations.dateLocal(message['datetime']))
                        .utc()
                        .format('DD.MM.YYYY HH:mm:ss')
                );
            }
            resolve(labels);
        });
    }

    unique(arr) {
        const obj = {};
        for (let i = 0; i < arr.length; i++) {
            const str = arr[i];
            obj[str] = true;
        }
        return Object.keys(obj);
    }

    renderChart(chartData, id) {
        const dom = document.getElementById(id);
        const innerContent = <HTMLElement>document.querySelector('.innerContent');
        this.chart = echarts.init(dom, {}, { renderer: 'canvas', height: 400, width: innerContent.clientWidth - 50 });
        let selected = _.get(this.settings, 'chartSelectedSeries.model_id_' + this.device.model.id, null);
        let end = 100;
        if (chartData.labels > 5000) {
            end = 30;
        } else {
            end = 100;
        }
        const option = {
            toolbox: {
                top: '10%',
                right: '1%',
                show: true,
                feature: {
                    magicType: {
                        type: ['line', 'bar'],
                        title: {
                            line: this.translate.instant('devices.data_show.msg_9'),
                            bar: this.translate.instant('devices.data_show.msg_10')
                        }
                    },
                    saveAsImage: {
                        type: 'jpeg',
                        title: this.translate.instant('devices.data_show.msg_8'),
                        show: true,
                        name: this.getDeviceName()
                    }
                }
            },
            tooltip: {
                trigger: 'axis',
                confine: true
            },
            legend: {
                data: chartData.inputs,
                type: 'scroll',
                selected: _.isNull(selected) ? chartData.inputs : selected
            },
            grid: {
                left: '2%',
                right: '5%',
                bottom: '12%',
                containLabel: true
            },
            dataZoom: [
                {
                    type: 'inside',
                    start: 0,
                    end: end
                },
                {
                    start: 0,
                    end: 100,
                    handleIcon:
                        'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
                    handleSize: '80%',
                    handleStyle: {
                        color: '#fff',
                        shadowBlur: 3,
                        shadowColor: 'rgba(0, 0, 0, 0.6)',
                        shadowOffsetX: 2,
                        shadowOffsetY: 2
                    }
                }
            ],
            xAxis: {
                type: 'category',
                data: chartData.labels
            },
            yAxis: {
                type: 'value'
            },
            series: chartData.series
        };
        this.chart.setOption(option, true);
        const _that = this;
        this.chart.on('legendselectchanged', function (params) {
            _.set(_that.settings, 'chartSelectedSeries.model_id_' + _that.device.model.id, params.selected);
            _that._cloudService.settings = _that.settings;
        });
        this.chart.on('magictypechanged', function (params) {
            _.set(_that.settings, 'chartType.model_id_' + _that.device.model.id, params.currentType);
            _that._cloudService.settings = _that.settings;
        });
    }

    getDeviceName() {
        const msg = (this.lang === 'en' ? 'for device ' : 'по устройству ');
        const deviceName = this.device !== undefined && this.device !== null ? msg + this.device.name : '';
        return this.translate.instant('devices.data_show.msg_7', {
            deviceName
        });
    }


    ngOnDestroy() {
        this.destroyed$.next(true);
        this.destroyed$.complete();
        this.subscribers.forEach((item) => {
            if (item !== undefined) {
                item.unsubscribe();
            }
        });
        if (this.chart !== undefined) {
            this.chart.dispose();
        }
    }
}
