import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {PassportService} from '../../auth/passport.service';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {CheckAccess, CloudService} from '../cloud.service';
import {ErrorsService} from '@core/services/errors.service';
import {InfrastructureReportTreeService} from '@cloud/reports/infrastructure-reports/components/infrastructure-report-tree/infrastructure-report-tree.service';
declare var jQuery: any;
@Injectable({
    providedIn: 'root'
})
export class ObjectsService {
    public mainApSpinner = false;
    public childApSpinner = false;
    public treeSpinner = false;
    public mapLoadingBar = false;
    public firstLoadingTreeSpinner = false;
    objectTabIndex$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    openCurrentObject$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    _baseURL: string;
    token: string;
    public headers = new HttpHeaders();
    selectedNodeSubject = new BehaviorSubject(null);
    selectedNodeSubjectChilds = new BehaviorSubject(null);
    editNodeSubject = new Subject();
    searchObjects = new Subject();
    expandedAllSubject = new Subject();
    deleteModalSubject = new BehaviorSubject(false);
    selectedNode: any;
    editedNode: any;
    objectUrls = {};
    openedObjects = {};
    openedObjectsBalanceGroups = {};
    openedObjectsInfrastructureReports = {};
    openedObjectsNewReportAccountingPoints = {};
    expandedAllValue;
    public expands = [];
    public expandsBalanceGroups = [];
    public expandsInfrastructureReports = [];
    public expandsNewReportAccountingPoints = [];
    selectedNodeChilds: any;

    constructor(
        private httpClient: HttpClient,
        private auth: PassportService,
        public _checkAccess: CheckAccess,
        private errors: ErrorsService,
        private _cloudService: CloudService,
        private infrastructureReportTreeService: InfrastructureReportTreeService
    ) {
        this._baseURL = environment.baseURL;
    }

    set node(node) {
        this.selectedNode = node?.object || node;
        this.selectedNodeChilds = node?.childs || [];
        this.selectedNodeSubject.next(node?.object || node);
        this.selectedNodeSubjectChilds.next(node?.childs || []);
    }

    get node(): any {
        return new Observable((observer) => {
            observer.next(this.selectedNode);
            observer.complete();
        });
    }

    get currentNode(): any {
        return this.selectedNode;
    }

    set editNode(node) {
        this.editedNode = node;
        this.editNodeSubject.next(node);
    }

    get editNode(): Observable<any> {
        return new Observable((observer) => {
            observer.next(this.editedNode);
            observer.complete();
        });
    }

    set expandedAll(value) {
        this.expandedAllValue = value;
        this.expandedAllSubject.next(value);
    }

    get expandedAll(): Observable<any> {
        return new Observable((observer) => {
            observer.next(this.expandedAllValue);
            observer.complete();
        });
    }

    cleanStructureTree() {
        this.expands = [];
        this.expandsBalanceGroups = [];
        this.expandsInfrastructureReports = [];
        this.openedObjects = {};
        this.openedObjectsBalanceGroups = {};
        this.openedObjectsInfrastructureReports = {};
        this.node = null;
    }

    addNewElement(sendObject) {
        if (this._checkAccess.listAccess('create', 6)) {
            this.headers = new HttpHeaders();
            this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
            this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
            return this.httpClient.post(this._baseURL + 'objects/add', sendObject, { headers: this.headers }).toPromise();
        } else {
            this.errors.showSnackbar('msg_101', true);
        }
    }

    getStructureTree(offset = null) {
        if (this._checkAccess.listAccess('read', 6)) {
            this.headers = new HttpHeaders();
            this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
            this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
            return this.httpClient.get(this._baseURL + `objects/${offset ? `map?offset=${offset}` : `map`}`, { headers: this.headers }).pipe(
                map((result: any) => {
                    return result;
                })
            );
        } else {
            this.errors.showSnackbar('msg_102', true);
        }
    }
    getObjectsDataToExport() {
        if (this._checkAccess.listAccess('read', 6)) {
            this.headers = new HttpHeaders();
            this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
            this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
            return this.httpClient.post(this._baseURL + `objects/all/export`, {}, { headers: this.headers, responseType: 'text' }).pipe(
                map((result: any) => {
                    return result;
                })
            );
        } else {
            this.errors.showSnackbar('msg_102', true);
        }
    }

    getBuildStructureTree(parent_id = null, isStats = true, search_string = null, parent_ids = null) {
        const with_stats  = (this._cloudService?.appVar?.current_company?.options?.tree_with_stats === 1 && isStats) ||
            (this._cloudService?.appVar?.current_company?.options?.tree_with_stats === undefined && isStats);
        const body: any = {with_stats};
        if (parent_id) {
            body['parent_id'] = parent_id;
        }
        if (search_string) {
            body['search_string'] = search_string;
        }
        if (parent_ids) {
            body['parent_ids'] = parent_ids;
        }
        if (this._checkAccess.listAccess('read', 6)) {
            this.headers = new HttpHeaders();
            this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
            this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
            return this.httpClient.post(this._baseURL + `objects/build_tree`, body, { headers: this.headers }).pipe(
                map((result: any) => {
                    return result.data;
                })
            );
        } else {
            this.errors.showSnackbar('msg_102', true);
        }
    }

    linkWithParentsChilds(object_ids) {
        const body = {object_ids};
        if (this._checkAccess.listAccess('read', 6)) {
            this.headers = new HttpHeaders();
            this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
            this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
            return this.httpClient.post(this._baseURL + `objects/link_with_parents_childs`, body , { headers: this.headers }).pipe(
                map((result: any) => {
                    return result.data;
                })
            );
        } else {
            this.errors.showSnackbar('msg_102', true);
        }
    }

    saveDevicesForObject(object_id, devices_ids) {
        if (
            (this._checkAccess.listAccess('update', 6) && this._checkAccess.listAccess('read', 4)) ||
            (this._checkAccess.listAccess('update', 6) && this._checkAccess.listAccess('access_all', 4))
        ) {
            this.headers = new HttpHeaders();
            this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
            this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
            return this.httpClient
                .post(
                    this._baseURL + 'objects/tie_devices',
                    {
                        object_id,
                        devices_ids: devices_ids
                    },
                    { headers: this.headers }
                )
                .toPromise();
        } else {
            this.errors.showSnackbar('msg_103', true);
        }
    }

    saveGatewaysForObject(object_id, gateways_ids) {
        if (
            (this._checkAccess.listAccess('update', 6) && this._checkAccess.listAccess('read', 4)) ||
            (this._checkAccess.listAccess('update', 6) && this._checkAccess.listAccess('access_all', 4))
        ) {
            this.headers = new HttpHeaders();
            this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
            this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
            return this.httpClient
                .post(
                    this._baseURL + 'objects/tie_gateways',
                    {
                        object_id,
                        gateways_ids: gateways_ids
                    },
                    { headers: this.headers }
                )
                .toPromise();
        } else {
            this.errors.showSnackbar('msg_104', true);
        }
    }

    saveBaseStationsForObject(object_id, bs_ids) {
        if (
            (this._checkAccess.listAccess('update', 6) && this._checkAccess.listAccess('read', 4)) ||
            (this._checkAccess.listAccess('update', 6) && this._checkAccess.listAccess('access_all', 4))
        ) {
            this.headers = new HttpHeaders();
            this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
            this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
            return this.httpClient
                .post(
                    this._baseURL + 'objects/tie_base_stations',
                    {
                        object_id,
                        bs_ids: bs_ids
                    },
                    { headers: this.headers }
                )
                .toPromise();
        } else {
            this.errors.showSnackbar('msg_105', true);
        }
    }

    saveUsersForObject(object_id, us_ids) {
        if ((this._checkAccess.listAccess('update', 6) || this._checkAccess.listAccess('access_all', 6)) && this._checkAccess.listAccess('read', 1)) {
            this.headers = new HttpHeaders();
            this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
            this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
            return this.httpClient
                .post(
                    this._baseURL + 'objects/tie_users',
                    {
                        object_id,
                        user_ids: us_ids
                    },
                    { headers: this.headers }
                )
                .toPromise();
        } else {
            this.errors.showSnackbar('msg_106', true);
        }
    }

    deleteObjectElement(objectId, removeTiedPoints = false) {
        if (this._checkAccess.listAccess('delete', 6) || this._checkAccess.listAccess('access_all', 6)) {
            this.headers = new HttpHeaders();
            this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
            this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
            return this.httpClient
                .put(
                    this._baseURL + 'objects/remove',
                    { object_id: objectId, remove_tied_points: removeTiedPoints },
                    { headers: this.headers }
                )
                .toPromise();
        } else {
            this.errors.showSnackbar('msg_107', true);
        }
    }

    updateSortElement(sortObject) {
        if (this._checkAccess.listAccess('update', 6) || this._checkAccess.listAccess('access_all', 6)) {
            this.headers = new HttpHeaders();
            this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
            this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
            return this.httpClient.post(this._baseURL + 'objects/update_sort', sortObject, { headers: this.headers });
        } else {
            this.errors.showSnackbar('msg_108', true);
        }
    }

    updateElement(elementObject) {
        if (this._checkAccess.listAccess('update', 6) || this._checkAccess.listAccess('access_all', 6)) {
            this.headers = new HttpHeaders();
            this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
            this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
            return this.httpClient.post(this._baseURL + 'objects/update', elementObject, { headers: this.headers }).toPromise();
        } else {
            this.errors.showSnackbar('msg_109', true);
        }
    }

    getAttributesList(object_id = null) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        const body = {};
        if (object_id) {
            body['object_id'] = object_id;
        }
        return this.httpClient.post(this._baseURL + 'objects/attributes', body, { headers: this.headers }).toPromise();
    }

    saveAttribute(name, value, object_id) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient
            .post(
                this._baseURL + 'objects/attribute/set',
                {
                    object_id: object_id,
                    attribute_name: name,
                    attribute_value: value
                },
                { headers: this.headers }
            )
            .toPromise();
    }

    getAccountingPointList(
        objectId,
        with_childs = false,
        tied_device = null,
        fieldSort = null,
        directionSort = null,
        search = null,
        paginate = false,
        previousPage = null,
        pageIndex = null,
        pageSize = null,
        device_state = null,
        resourceTypeIds = null,
    ) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        const body = {
            object_id: objectId,
            with_childs,
            tied_device,
            device_state
        };
        if (fieldSort) {
            body['sort_field'] = fieldSort;
        }
        if (directionSort) {
            body['sort'] = directionSort;
        }
        if (search) {
            body['search_string'] = search;
        }
        if (paginate === true) {
            body['paginate'] = paginate;
        }
        if (pageSize !== null) {
            body['per_page'] = pageSize;
        }
        if (pageIndex !== null) {
            body['page'] = pageIndex;
        }
        if (previousPage !== null) {
            body['last_page'] = previousPage;
        }
        if (resourceTypeIds !== null) {
            body['resource_type_ids'] = resourceTypeIds.map(v => {
                if (v === 0) {
                    v = 2;
                }
                return v;
            });
        }
        if (this.infrastructureReportTreeService.selectedConsumer !== null && this.infrastructureReportTreeService.selectedConsumer?.length > 0) {
            body['consumer_id'] = this.infrastructureReportTreeService.selectedConsumer;
        }
        if (this.infrastructureReportTreeService.selectedSupplier !== null && this.infrastructureReportTreeService.selectedSupplier?.length > 0) {
            body['supplier_id'] = this.infrastructureReportTreeService.selectedSupplier;
        }
        if (this.infrastructureReportTreeService.selectedPriceCategory !== null && this.infrastructureReportTreeService.selectedPriceCategory?.length > 0) {
            body['price_category'] = this.infrastructureReportTreeService.selectedPriceCategory;
        }
        return this.httpClient.post(this._baseURL + 'objects/accounting_point/list', {...body}, {headers: this.headers}).pipe(
            map((result: any) => {
                return result;
            })
        );
    }

    getAccountingPointListByIds(point_ids, with_childs = false, tied_device = null) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient
            .post(
                this._baseURL + 'objects/accounting_point/company/list',
                {
                    point_ids,
                    with_childs,
                    tied_device
                },
                { headers: this.headers }
            )
            .pipe(
                map((result: any) => {
                    return result.data.accounting_points;
                })
            );
    }

    getNestedAccountingPoint(body) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.post(this._baseURL + 'objects/accounting_point/nested', body, { headers: this.headers }).pipe(
            map((result: any) => {
                return result.data.accounting_points;
            })
        );
    }

    addAccountingPoint(accountingPoint) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.post(this._baseURL + 'objects/accounting_point/create', accountingPoint, { headers: this.headers }).pipe(
            map((result: any) => {
                return result;
            })
        );
    }
    importAccountingPoints(data: any) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.post(this._baseURL + 'objects/accounting_point/import', data, { headers: this.headers }).pipe(
            map((result: any) => {
                return result;
            })
        );
    }

    editAccountingPoint(accountingPoint) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.put(this._baseURL + `objects/accounting_point/update`, accountingPoint, { headers: this.headers }).pipe(
            map((result: any) => {
                return result;
            })
        );
    }

    deleteAccountingPoint(accountingPointId) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient
            .post(this._baseURL + 'objects/accounting_point/delete', { point_id: accountingPointId }, { headers: this.headers })
            .pipe(
                map((result: any) => {
                    return result;
                })
            );
    }

    deAttachDeviceAccountingPoint(accountingPointId, deviceId) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient
            .put(this._baseURL + 'objects/accounting_point/untie_device', { point_id: accountingPointId }, { headers: this.headers })
            .pipe(
                map((result: any) => {
                    return result;
                })
            );
    }

    attachDevice(accountingPointId, device) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient
            .put(
                this._baseURL + 'objects/accounting_point/tie_device',
                { point_id: accountingPointId, device_id: device.id },
                { headers: this.headers }
            )
            .pipe(
                map((result: any) => {
                    return result;
                })
            );
    }

    changeAccountingPointDevice(accountingPointId, device) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient
            .put(
                this._baseURL + 'objects/accounting_point/replace_device',
                { point_id: accountingPointId, device_id: device.id },
                { headers: this.headers }
            )
            .pipe(
                map((result: any) => {
                    return result;
                })
            );
    }

    getAllAccountingPointList(
        tied_object = null,
        tied_device = null,
        fieldSort = null,
        directionSort = null,
        search = null,
        paginate = false,
        previousPage = null,
        pageIndex = null,
        pageSize = null,
        device_state = null,
        tied_consumer_supplier = null
    ) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        const body = {
            tied_object,
            tied_device,
            device_state
        };
        if (tied_consumer_supplier) {
            body['tied_consumer'] = true;
            body['tied_supplier'] = true;
        }
        if (fieldSort) {
            body['sort_field'] = fieldSort;
        }
        if (directionSort) {
            body['sort'] = directionSort;
        }
        if (search) {
            body['search_string'] = search;
        }
        if (paginate === true) {
            body['paginate'] = paginate;
        }
        if (pageSize !== null) {
            body['per_page'] = pageSize;
        }
        if (pageIndex !== null) {
            body['page'] = pageIndex;
        }
        if (previousPage !== null) {
            body['last_page'] = previousPage;
        }
        return this.httpClient.post(this._baseURL + 'objects/accounting_point/company/list', { ...body }, { headers: this.headers }).pipe(
            map((result: any) => {
                return result.data.accounting_points;
            })
        );
    }

    getTabsByAccountingPointId(point_id) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.post(this._baseURL + 'objects/accounting_point/tabs', { point_id }, { headers: this.headers }).pipe(
            map((result: any) => {
                return result.data.tabs;
            })
        );
    }

    getTabsByDeviceGroupId(deviceGroupId) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient
            .post(this._baseURL + 'device/model/tabs', { device_group_id: deviceGroupId }, { headers: this.headers })
            .pipe(
                map((result: any) => {
                    return result.data.merged_tabs;
                })
            );
    }

    getAccountingPointById(point_id) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.get(this._baseURL + `objects/accounting_point/${point_id}`, { headers: this.headers }).pipe(
            map((result: any) => {
                return result.data.accounting_point;
            })
        );
    }

    getAccountingPointLogs(point_id) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.post(this._baseURL + `objects/accounting_point/logs/`, { point_id }, { headers: this.headers }).pipe(
            map((result: any) => {
                return result.data.accounting_point_logs;
            })
        );
    }

    untieAccountingPoint(point_id) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.post(this._baseURL + `objects/accounting_point/untie_object/`, { point_id }, { headers: this.headers }).pipe(
            map((result: any) => {
                return result.msg;
            })
        );
    }

    tieAccountingPoint(point_id, object_id) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient
            .post(
                this._baseURL + `objects/accounting_point/tie_object/`,
                {
                    point_id,
                    object_id
                },
                { headers: this.headers }
            )
            .pipe(
                map((result: any) => {
                    return result.msg;
                })
            );
    }

    getAllTransformators() {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.post(this._baseURL + `reference/transformer/list`, {}, { headers: this.headers }).pipe(
            map((result: any) => {
                return result.data.transformers;
            })
        );
    }

    getAddressByCoords(coords, lang = 'ru') {
        return this.httpClient.get(
            `https://nominatim.openstreetmap.org/reverse?format=json&lat=${coords.lat}&lon=${coords.lng}&zoom=18&addressdetails=1&accept-language=${lang}`
        );
    }

    getCoordinatesByAddress(address, lang = 'ru') {
        return this.httpClient.get(
            `https://nominatim.openstreetmap.org/search.php?q=${address}&polygon_geojson=1&accept-language=ru&limit=1&format=jsonv2`
        );
    }

    getAccountingPointCompatibleType() {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.get(this._baseURL + `objects/accounting_point/resource/types`, { headers: this.headers }).pipe(
            map((result: any) => {
                return result.data.resource_types;
            })
        );
    }

    getObject(id, with_childs?) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.post<any>(`${this._baseURL}objects/object/get`, { object_id: id, with_childs}, { headers: this.headers });
    }
    getObjects(objects_id, with_childs?) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.post<any>(`${this._baseURL}objects/objects/get`, { objects_id, with_childs}, { headers: this.headers });
    }

    getDadataInfoFlat(address, flatNum) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.post<any>(
            `${this._baseURL}objects/get_info_from_dadata`,
            { address: `${address} ${flatNum}` },
            { headers: this.headers }
        );
    }

    // Api returns 500 internal error
    // getDadataAddress(address) {
    //     this.headers = new HttpHeaders();
    //     this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
    //     this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
    //     return this.httpClient.post<any>(
    //         `${this._baseURL}objects/get_info_from_dadata`,
    //         {address: `${address}`},
    //         {headers: this.headers}
    //     );
    // }

    getHeatCalculatingByObjectId(objectId) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.post<any>(
            `${this._baseURL}objects/get_heat_calculation`,
            { object_id: objectId },
            { headers: this.headers }
        );
    }

    updateAccountingPointStandard(pointId, standard) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.post<any>(`${this._baseURL}objects/accounting_point/update_standard/${pointId}`, standard, {
            headers: this.headers
        });
    }

    getSearchValueGAR(type: string, level: number, name: string, with_parents: boolean, paramsForObjectCreation: object) {
        const body = {
            type,
            with_parents,
            search: {
                level,
                name
            },
            ...paramsForObjectCreation
        };
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.post(this._baseURL + 'proxy/gar_hints', body, { headers: this.headers });
    }

    importObjectsFromGAR(type: string, rewrite: boolean, paramsForObjectCreation: object) {
        const body = {
            type,
            rewrite,
            ...paramsForObjectCreation
        };
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.post(this._baseURL + 'objects/add_automatically', body, { headers: this.headers });
    }

    reportInfrastructureCreate(body) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');

        return this.httpClient.post(this._baseURL + 'report/infrastructure/create', { ...body }, { headers: this.headers });
    }

    massTieDevicesToObjects(body) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');

        return this.httpClient.post(this._baseURL + 'objects/mass_tie_devices', body, { headers: this.headers });
    }

    renderAddressString(currentNode) {
        setTimeout((_) => {
            jQuery('#addressObject').suggestions({
                token: '7e1919b38023571b3b496eff7160f9ded9eb3f1c',
                type: 'ADDRESS',
                count: 5,
                /* Вызывается, когда пользователь выбирает одну из подсказок */
                onSelect: function (suggestion) {
                    if (suggestion.hasOwnProperty('unrestricted_value') && suggestion.unrestricted_value !== null) {
                        currentNode.properties.address.unrestricted_value = suggestion.unrestricted_value;
                        if (suggestion.data.country !== null) {
                            currentNode.properties.address.country = suggestion.data.country;
                        } else {
                            currentNode.properties.address.country = undefined;
                        }
                        if (suggestion.data.region_with_type !== null) {
                            currentNode.properties.address.region = suggestion.data.region_with_type;
                        } else {
                            currentNode.properties.address.region = undefined;
                        }
                        if (suggestion.data.city !== null) {
                            currentNode.properties.address.city = suggestion.data.city;
                        } else {
                            currentNode.properties.address.city = undefined;
                        }
                        if (suggestion.data.street_with_type !== null) {
                            currentNode.properties.address.street = suggestion.data.street_with_type;
                        } else {
                            currentNode.properties.address.street = undefined;
                        }
                        if (suggestion.data.house !== null) {
                            currentNode.properties.address.house = suggestion.data.house;
                        } else {
                            currentNode.properties.address.house = undefined;
                        }
                        if (suggestion.data.area !== null) {
                            currentNode.properties.address.area = suggestion.data.area;
                        } else {
                            currentNode.properties.address.area = undefined;
                        }
                        if (suggestion.data.settlement !== null) {
                            currentNode.properties.address.settlement = suggestion.data.settlement;
                        } else {
                            currentNode.properties.address.settlement = undefined;
                        }
                        if (suggestion.data.city_district !== null) {
                            currentNode.properties.address.city_district = suggestion.data.city_district;
                        } else {
                            currentNode.properties.address.city_district = undefined;
                        }
                        if (suggestion.data.block !== null) {
                            currentNode.properties.address.block = suggestion.data.block;
                        } else {
                            currentNode.properties.address.block = undefined;
                        }

                        if (suggestion.data.geo_lat !== null && suggestion.data.geo_lon !== null) {
                            currentNode.properties.address.coordinates = [
                                suggestion.data.geo_lat,
                                suggestion.data.geo_lon
                            ];
                        }
                    }
                }
            });
        }, 500);
        return currentNode;
    }

}
